import moment from "moment";
import React from "react";
import {
    loadFont,
    marketingCreativeColorSchemes,
    marketingCreativeTemplateKeys,
} from "ui/pages/marketing/MarketingCreatives/data";
import { handleDateFormat } from "utils/Utils";

export const ColdancePostSimple = (props) => {
    const { textData, coverImage } = props;

    const defaultColorScheme =
        marketingCreativeColorSchemes?.[marketingCreativeTemplateKeys.Coldance]
            ?.schemes?.[0];

    const color1 = props?.colors?.[0] || defaultColorScheme?.[0];
    const color2 = props?.colors?.[1] || defaultColorScheme?.[1];
    const color3 = props?.colors?.[2] || defaultColorScheme?.[2];

    const startDateExists = textData?.start_date_time?.exists;
    const startDate = startDateExists && textData?.start_date_time?.date;
    const startTime = startDateExists && textData?.start_date_time?.time;
    const endDateExists = textData?.end_date_time?.exists;
    const endDate = endDateExists && textData?.end_date_time?.date;
    const endTime = endDateExists && textData?.end_date_time?.time;

    React.useEffect(() => {
        loadFont(
            "https://fonts.googleapis.com/css2?family=Josefin+Sans&display=swap"
        );
    }, []);

    return (
        <div style={{ fontFamily: "Josefin Sans" }}>
            {textData?.heading ? (
                <div
                    style={{
                        position: "absolute",
                        color: color3,
                        top: "111px",
                        fontWeight: 600,
                        fontSize: "72px",
                        width: "1080px",
                        wordBreak: "break-word",
                        textAlign: "center",
                    }}
                >
                    {textData?.heading}
                </div>
            ) : null}
            {textData?.sub_heading ? (
                <div
                    style={{
                        position: "absolute",
                        color: color3,
                        top: "302px",
                        fontWeight: 500,
                        fontSize: "36px",
                        width: "1080px",
                        wordBreak: "break-word",
                        textAlign: "center",
                    }}
                >
                    {textData?.sub_heading}
                </div>
            ) : null}
            {startDate ? (
                <div
                    style={{
                        position: "absolute",
                        color: color3,
                        top: "473px",
                        fontWeight: 600,
                        fontSize: "36px",
                        width: "1080px",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        whiteSpace: "nowrap",
                    }}
                >
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <svg
                            style={{
                                marginRight: "20px",
                            }}
                            width="54"
                            height="52"
                            viewBox="0 0 54 52"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M0 12.9999C0 10.5076 0 9.26145 0.535899 8.33325C0.886972 7.72517 1.39192 7.22022 2 6.86915C2.9282 6.33325 4.17436 6.33325 6.66667 6.33325H46.6667C49.159 6.33325 50.4051 6.33325 51.3333 6.86915C51.9414 7.22022 52.4464 7.72517 52.7974 8.33325C53.3333 9.26145 53.3333 10.5076 53.3333 12.9999C53.3333 15.4922 53.3333 16.7384 52.7974 17.6666C52.4464 18.2747 51.9414 18.7796 51.3333 19.1307C50.4051 19.6666 49.159 19.6666 46.6667 19.6666H6.66666C4.17436 19.6666 2.9282 19.6666 2 19.1307C1.39192 18.7796 0.886972 18.2747 0.535899 17.6666C0 16.7384 0 15.4922 0 12.9999Z"
                                fill={color3}
                            />
                            <path
                                d="M0 43.6668C0 47.438 0 49.3236 1.17157 50.4952C2.34315 51.6667 4.22876 51.6667 8 51.6667H45.3333C49.1046 51.6667 50.9902 51.6667 52.1618 50.4952C53.3333 49.3236 53.3333 47.438 53.3333 43.6667V33.0001C53.3333 29.2288 53.3333 27.3432 52.1618 26.1717C50.9902 25.0001 49.1046 25.0001 45.3333 25.0001H7.99999C4.22876 25.0001 2.34315 25.0001 1.17157 26.1717C0 27.3432 0 29.2288 0 33.0001V43.6668Z"
                                fill={color3}
                            />
                            <path
                                d="M13.332 1L13.332 9"
                                stroke={color3}
                                stroke-width="2"
                                stroke-linecap="round"
                            />
                            <path
                                d="M40 1L40 9"
                                stroke={color3}
                                stroke-width="2"
                                stroke-linecap="round"
                            />
                        </svg>
                        <div style={{ position: "relative", top: "6px" }}>
                            {endDate
                                ? handleDateFormat(startDate, false, false) +
                                  " - " +
                                  handleDateFormat(endDate, false, false)
                                : null}
                            {!endDate
                                ? handleDateFormat(startDate, false)
                                : null}
                        </div>
                    </div>
                    {startTime ? (
                        <div
                            style={{
                                display: "flex",
                                alignItems: "center",
                                marginTop: "21px",
                            }}
                        >
                            <svg
                                style={{
                                    marginRight: "20px",
                                }}
                                width="53"
                                height="53"
                                viewBox="0 0 53 53"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <circle
                                    cx="26.5"
                                    cy="26.5"
                                    r="23"
                                    stroke={color3}
                                    stroke-width="7"
                                />
                                <path
                                    d="M37.7277 27.0905H27.3413C27.2032 27.0905 27.0913 26.9786 27.0913 26.8405V17.636"
                                    stroke={color3}
                                    stroke-width="7"
                                    stroke-linecap="round"
                                />
                            </svg>
                            <div
                                style={{
                                    position: "relative",
                                    top: "-2px",
                                    marginTop: "21px",
                                }}
                            >
                                {startTime || null}
                                {endTime ? " - " + endTime : null}
                            </div>
                        </div>
                    ) : null}
                </div>
            ) : null}
            {textData?.name ? (
                <div
                    style={{
                        position: "absolute",
                        color: color3,
                        bottom: "256px",
                        right: "10px",
                        width: "380px",
                        fontWeight: 500,
                        fontSize: "48px",
                        wordBreak: "break-word",
                    }}
                >
                    {textData?.name}
                </div>
            ) : null}
            {textData?.designation ? (
                <div
                    style={{
                        position: "absolute",
                        color: color3,
                        top: "860px",
                        right: "10px",
                        width: "380px",
                        fontWeight: 800,
                        fontSize: "32px",
                        wordBreak: "break-word",
                    }}
                >
                    {textData?.designation}
                </div>
            ) : null}
            {textData?.org_name ? (
                <div
                    style={{
                        position: "absolute",
                        color: color3,
                        top: "908px",
                        right: "10px",
                        width: "380px",
                        fontWeight: 600,
                        fontSize: "32px",
                        wordBreak: "break-word",
                    }}
                >
                    {textData?.org_name}
                </div>
            ) : null}
            {coverImage ? (
                <div
                    style={{
                        position: "absolute",
                        width: "378px",
                        height: "378px",
                        top: "675px",
                        left: "300px",
                        backgroundImage: "url(" + coverImage + ")",
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "cover",
                        borderRadius: "50%",
                    }}
                />
            ) : null}

            <svg
                width="1080"
                height="1080"
                viewBox="0 0 1080 1080"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <g clip-path="url(#clip0_689_2116)">
                    <rect width="1080" height="1080" fill={color2} />
                    <path
                        d="M672.5 568L-385 -364V1554L672.5 568Z"
                        fill="url(#paint0_radial_689_2116)"
                    />
                    <path
                        d="M22 986L1079.5 1918L1079.5 0L22 986Z"
                        fill="url(#paint1_radial_689_2116)"
                    />
                    <path
                        d="M408.144 422L437.503 403.177L466.88 422L496.238 403.177L525.597 422L551.491 405.397L577.403 422L606.762 403.177L636.138 422L665.497 403.177L694.856 422L724 403.315L720.75 401.221L694.856 417.823L665.497 399L636.138 417.823L606.762 399L577.403 417.823L551.491 401.209L525.597 417.823L496.238 399L466.88 417.823L437.503 399L408.144 417.823L382.232 401.221L379 403.315L408.144 422Z"
                        fill={color1}
                    />
                    <path
                        d="M488.387 644.387C366.884 644.387 268.387 742.884 268.387 864.387C268.387 985.889 366.884 1084.39 488.387 1084.39V644.387ZM1218 1084.39H1438V644.387H1218V1084.39ZM488.387 1084.39H1218V644.387H488.387V1084.39Z"
                        fill={color1}
                    />
                    <circle
                        cx="488.023"
                        cy="864.023"
                        r="189.023"
                        fill="black"
                        fill-opacity="0.4"
                    />
                </g>
                <defs>
                    <radialGradient
                        id="paint0_radial_689_2116"
                        cx="0"
                        cy="0"
                        r="1"
                        gradientUnits="userSpaceOnUse"
                        gradientTransform="translate(-278 -180.5) rotate(90.6323) scale(1631.1 899.316)"
                    >
                        <stop offset="0.00412144" stop-color={color1} />
                        <stop offset="0.770369" stop-color={color2} />
                    </radialGradient>
                    <radialGradient
                        id="paint1_radial_689_2116"
                        cx="0"
                        cy="0"
                        r="1"
                        gradientUnits="userSpaceOnUse"
                        gradientTransform="translate(972.5 1734.5) rotate(-89.3677) scale(1631.1 899.316)"
                    >
                        <stop offset="0.00412144" stop-color="#407D6B" />
                        <stop offset="0.770369" stop-color={color2} />
                    </radialGradient>
                    <clipPath id="clip0_689_2116">
                        <rect width="1080" height="1080" fill={color2} />
                    </clipPath>
                </defs>
            </svg>
        </div>
    );
};
