import moment from "moment";
import React from "react";
import {
    loadFont,
    marketingCreativeColorSchemes,
    marketingCreativeTemplateKeys,
} from "ui/pages/marketing/MarketingCreatives/data";
import { handleDateFormat } from "utils/Utils";

export const ColdanceStorySimple = (props) => {
    const { textData, coverImage } = props;

    const defaultColorScheme =
        marketingCreativeColorSchemes?.[marketingCreativeTemplateKeys.Coldance]
            ?.schemes?.[0];

    const color1 = props?.colors?.[0] || defaultColorScheme?.[0];
    const color2 = props?.colors?.[1] || defaultColorScheme?.[1];
    const color3 = props?.colors?.[2] || defaultColorScheme?.[2];

    const startDateExists = textData?.start_date_time?.exists;
    const startDate = startDateExists && textData?.start_date_time?.date;
    const startTime = startDateExists && textData?.start_date_time?.time;
    const endDateExists = textData?.end_date_time?.exists;
    const endDate = endDateExists && textData?.end_date_time?.date;
    const endTime = endDateExists && textData?.end_date_time?.time;

    React.useEffect(() => {
        loadFont(
            "https://fonts.googleapis.com/css2?family=Josefin+Sans&display=swap"
        );
    }, []);

    return (
        <div style={{ fontFamily: "Josefin Sans" }}>
            {textData?.heading ? (
                <div
                    style={{
                        position: "absolute",
                        color: color3,
                        top: "263px",
                        fontWeight: 600,
                        fontSize: "85px",
                        width: "1080px",
                        wordBreak: "break-word",
                        textAlign: "center",
                    }}
                >
                    {textData?.heading}
                </div>
            ) : null}
            {textData?.sub_heading ? (
                <div
                    style={{
                        position: "absolute",
                        color: color3,
                        top: "467px",
                        fontWeight: 500,
                        fontSize: "40px",
                        width: "1080px",
                        wordBreak: "break-word",
                        textAlign: "center",
                    }}
                >
                    {textData?.sub_heading}
                </div>
            ) : null}
            {startDate ? (
                <div
                    style={{
                        position: "absolute",
                        color: color3,
                        top: "673px",
                        fontWeight: 600,
                        fontSize: "36px",
                        width: "1080px",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        whiteSpace: "nowrap",
                    }}
                >
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <svg
                            style={{
                                marginRight: "20px",
                            }}
                            width="54"
                            height="52"
                            viewBox="0 0 54 52"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M0 12.9999C0 10.5076 0 9.26145 0.535899 8.33325C0.886972 7.72517 1.39192 7.22022 2 6.86915C2.9282 6.33325 4.17436 6.33325 6.66667 6.33325H46.6667C49.159 6.33325 50.4051 6.33325 51.3333 6.86915C51.9414 7.22022 52.4464 7.72517 52.7974 8.33325C53.3333 9.26145 53.3333 10.5076 53.3333 12.9999C53.3333 15.4922 53.3333 16.7384 52.7974 17.6666C52.4464 18.2747 51.9414 18.7796 51.3333 19.1307C50.4051 19.6666 49.159 19.6666 46.6667 19.6666H6.66666C4.17436 19.6666 2.9282 19.6666 2 19.1307C1.39192 18.7796 0.886972 18.2747 0.535899 17.6666C0 16.7384 0 15.4922 0 12.9999Z"
                                fill={color3}
                            />
                            <path
                                d="M0 43.6668C0 47.438 0 49.3236 1.17157 50.4952C2.34315 51.6667 4.22876 51.6667 8 51.6667H45.3333C49.1046 51.6667 50.9902 51.6667 52.1618 50.4952C53.3333 49.3236 53.3333 47.438 53.3333 43.6667V33.0001C53.3333 29.2288 53.3333 27.3432 52.1618 26.1717C50.9902 25.0001 49.1046 25.0001 45.3333 25.0001H7.99999C4.22876 25.0001 2.34315 25.0001 1.17157 26.1717C0 27.3432 0 29.2288 0 33.0001V43.6668Z"
                                fill={color3}
                            />
                            <path
                                d="M13.332 1L13.332 9"
                                stroke={color3}
                                stroke-width="2"
                                stroke-linecap="round"
                            />
                            <path
                                d="M40 1L40 9"
                                stroke={color3}
                                stroke-width="2"
                                stroke-linecap="round"
                            />
                        </svg>
                        <div style={{ position: "relative", top: "6px" }}>
                            {endDate
                                ? handleDateFormat(startDate, false, false) +
                                  " - " +
                                  handleDateFormat(endDate, false, false)
                                : null}
                            {!endDate
                                ? handleDateFormat(startDate, false)
                                : null}
                        </div>
                    </div>
                    {startTime ? (
                        <div
                            style={{
                                display: "flex",
                                alignItems: "center",
                                marginTop: "60px",
                            }}
                        >
                            <svg
                                style={{
                                    marginRight: "20px",
                                }}
                                width="53"
                                height="53"
                                viewBox="0 0 53 53"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <circle
                                    cx="26.5"
                                    cy="26.5"
                                    r="23"
                                    stroke="white"
                                    stroke-width="7"
                                />
                                <path
                                    d="M37.7277 27.0905H27.3413C27.2032 27.0905 27.0913 26.9786 27.0913 26.8405V17.636"
                                    stroke="white"
                                    stroke-width="7"
                                    stroke-linecap="round"
                                />
                            </svg>
                            <div style={{ position: "relative", top: "6px" }}>
                                {startTime || null}
                                {endTime ? " - " + endTime : null}
                            </div>
                        </div>
                    ) : null}
                </div>
            ) : null}
            {textData?.name ? (
                <div
                    style={{
                        position: "absolute",
                        color: color3,
                        top: "1500px",
                        right: "22px",
                        fontWeight: 500,
                        fontSize: "64px",
                        textAlign: "right",
                        whiteSpace: "nowrap",
                    }}
                >
                    {textData?.name}
                </div>
            ) : null}
            {textData?.designation ? (
                <div
                    style={{
                        position: "absolute",
                        color: color3,
                        top: "1606px",
                        right: "22px",
                        fontWeight: 800,
                        fontSize: "36px",
                        textAlign: "right",
                        whiteSpace: "nowrap",
                    }}
                >
                    {textData?.designation}
                </div>
            ) : null}
            {textData?.org_name ? (
                <div
                    style={{
                        position: "absolute",
                        color: color3,
                        top: "1654px",
                        right: "22px",
                        fontWeight: 600,
                        fontSize: "36px",
                        textAlign: "right",
                        whiteSpace: "nowrap",
                    }}
                >
                    {textData?.org_name}
                </div>
            ) : null}
            {textData?.additional_text ? (
                <div
                    style={{
                        position: "absolute",
                        color: color3,
                        top: "1779px",
                        left: "51px",
                        fontWeight: 600,
                        fontSize: "32px",
                        whiteSpace: "nowrap",
                    }}
                >
                    {textData?.additional_text}
                </div>
            ) : null}
            {textData?.action_text ? (
                <div
                    style={{
                        position: "absolute",
                        color: color2,
                        backgroundColor: color3,
                        top: "1745px",
                        right: "22px",
                        padding: "34px 63px",
                        fontWeight: 700,
                        fontSize: "40px",
                        borderRadius: "100px",
                        whiteSpace: "nowrap",
                    }}
                >
                    {textData?.action_text}
                </div>
            ) : null}
            {coverImage ? (
                <div
                    style={{
                        position: "absolute",
                        width: "519px",
                        height: "519px",
                        top: "960px",
                        left: "409px",
                        backgroundImage: "url(" + coverImage + ")",
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "cover",
                        borderRadius: "50%",
                    }}
                />
            ) : null}

            <svg
                width="1080"
                height="1920"
                viewBox="0 0 1080 1920"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <g clip-path="url(#clip0_689_2058)">
                    <rect width="1080" height="1920" fill={color2} />
                    <path
                        d="M22 988L1079.5 1920L1079.5 2L22 988Z"
                        fill="url(#paint0_radial_689_2058)"
                    />
                    <path
                        d="M1057.5 933L0 1V1919L1057.5 933Z"
                        fill="url(#paint1_radial_689_2058)"
                    />
                    <path
                        d="M918.142 1084.72C843.429 947.123 671.318 896.145 533.721 970.858C396.123 1045.57 345.145 1217.68 419.858 1355.28L918.142 1084.72ZM897.821 2235.54L1033.1 2484.68L1531.38 2214.12L1396.1 1964.98L897.821 2235.54ZM419.858 1355.28L897.821 2235.54L1396.1 1964.98L918.142 1084.72L419.858 1355.28Z"
                        fill={color1}
                    />
                    <circle
                        cx="668.5"
                        cy="1219.5"
                        r="259.5"
                        fill="black"
                        fill-opacity="0.5"
                    />
                    <path
                        d="M396.144 587L425.503 568.177L454.88 587L484.238 568.177L513.597 587L539.491 570.397L565.403 587L594.762 568.177L624.138 587L653.497 568.177L682.856 587L712 568.315L708.75 566.221L682.856 582.823L653.497 564L624.138 582.823L594.762 564L565.403 582.823L539.491 566.209L513.597 582.823L484.238 564L454.88 582.823L425.503 564L396.144 582.823L370.232 566.221L367 568.315L396.144 587Z"
                        fill={color1}
                    />
                </g>
                <defs>
                    <radialGradient
                        id="paint0_radial_689_2058"
                        cx="0"
                        cy="0"
                        r="1"
                        gradientUnits="userSpaceOnUse"
                        gradientTransform="translate(972.5 1736.5) rotate(-89.3677) scale(1631.1 899.316)"
                    >
                        <stop offset="0.00412144" stop-color={color1} />
                        <stop offset="0.770369" stop-color={color2} />
                    </radialGradient>
                    <radialGradient
                        id="paint1_radial_689_2058"
                        cx="0"
                        cy="0"
                        r="1"
                        gradientUnits="userSpaceOnUse"
                        gradientTransform="translate(107 184.5) rotate(90.6323) scale(1631.1 899.316)"
                    >
                        <stop offset="0.00412144" stop-color={color1} />
                        <stop offset="0.770369" stop-color={color2} />
                    </radialGradient>
                    <clipPath id="clip0_689_2058">
                        <rect width="1080" height="1920" fill={color3} />
                    </clipPath>
                </defs>
            </svg>
        </div>
    );
};
