import moment from "moment";
import React from "react";
import {
    loadFont,
    marketingCreativeColorSchemes,
    marketingCreativeTemplateKeys,
} from "ui/pages/marketing/MarketingCreatives/data";
import { handleDateFormat } from "utils/Utils";

export const CalperStorySimple = (props) => {
    const { textData, coverImage } = props;

    const defaultColorScheme =
        marketingCreativeColorSchemes?.[marketingCreativeTemplateKeys.Calper]
            ?.schemes?.[0];

    const color1 = props?.colors?.[0] || defaultColorScheme?.[0];
    const color2 = props?.colors?.[1] || defaultColorScheme?.[1];
    const color3 = props?.colors?.[2] || defaultColorScheme?.[2];

    const startDateExists = textData?.start_date_time?.exists;
    const startDate = startDateExists && textData?.start_date_time?.date;
    const startTime = startDateExists && textData?.start_date_time?.time;
    const endDateExists = textData?.end_date_time?.exists;
    const endDate = endDateExists && textData?.end_date_time?.date;
    const endTime = endDateExists && textData?.end_date_time?.time;

    React.useEffect(() => {
        loadFont(
            "https://fonts.googleapis.com/css2?family=Open+Sans&display=swap"
        );
    }, []);

    return (
        <div style={{ fontFamily: "Open Sans" }}>
            {textData?.heading ? (
                <div
                    style={{
                        position: "absolute",
                        color: color3,
                        bottom: "150px",
                        fontWeight: 800,
                        fontSize: "75px",
                        width: "1080px",
                        paddingLeft: "86px",
                        paddingRight: "86px",
                        wordBreak: "break-word",
                        textAlign: "center",
                        zIndex: 2,
                        textTransform: "uppercase",
                        textShadow: `-4px -4px ${color2}`,
                    }}
                >
                    {textData?.heading}
                </div>
            ) : null}
            <svg
                width="823"
                height="96"
                style={{
                    position: "absolute",
                    top: "1714px",
                    left: "128px",
                    zIndex: 1,
                }}
                viewBox="0 0 823 96"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <rect width="823" height="96" fill={color2} />
            </svg>

            {textData?.sub_heading ? (
                <div
                    style={{
                        position: "absolute",
                        color: color3,
                        top: "1763px",
                        fontWeight: 400,
                        fontSize: "36px",
                        width: "1080px",
                        paddingLeft: "128px",
                        paddingRight: "128px",
                        wordBreak: "break-word",
                        textAlign: "center",
                        zIndex: 2,
                    }}
                >
                    {textData?.sub_heading}
                </div>
            ) : null}
            {startDate ? (
                <div
                    style={{
                        position: "absolute",
                        color: color3,
                        top: "454px",
                        left: "399px",
                        fontWeight: 600,
                        fontSize: "36px",
                        backgroundColor: `${color2}80`,
                        zIndex: 1,
                        paddingLeft: "100px",
                        paddingRight: "100px",
                        whiteSpace: "nowrap",
                    }}
                >
                    {textData?.action_text ? (
                        <div
                            style={{
                                width: "100%",
                                height: "77px",
                            }}
                        />
                    ) : null}
                    <div className="d-flex align-items-center mt-3 mb-3">
                        <svg
                            width="54"
                            height="52"
                            style={{ marginRight: "20px" }}
                            viewBox="0 0 54 52"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M0 12.9999C0 10.5076 0 9.26145 0.535899 8.33325C0.886972 7.72517 1.39192 7.22022 2 6.86915C2.9282 6.33325 4.17436 6.33325 6.66667 6.33325H46.6667C49.159 6.33325 50.4051 6.33325 51.3333 6.86915C51.9414 7.22022 52.4464 7.72517 52.7974 8.33325C53.3333 9.26145 53.3333 10.5076 53.3333 12.9999C53.3333 15.4922 53.3333 16.7384 52.7974 17.6666C52.4464 18.2747 51.9414 18.7796 51.3333 19.1307C50.4051 19.6666 49.159 19.6666 46.6667 19.6666H6.66666C4.17436 19.6666 2.9282 19.6666 2 19.1307C1.39192 18.7796 0.886972 18.2747 0.535899 17.6666C0 16.7384 0 15.4922 0 12.9999Z"
                                fill={color3}
                            />
                            <path
                                d="M0 43.6668C0 47.438 0 49.3236 1.17157 50.4952C2.34315 51.6667 4.22876 51.6667 8 51.6667H45.3333C49.1046 51.6667 50.9902 51.6667 52.1618 50.4952C53.3333 49.3236 53.3333 47.438 53.3333 43.6667V33.0001C53.3333 29.2288 53.3333 27.3432 52.1618 26.1717C50.9902 25.0001 49.1046 25.0001 45.3333 25.0001H7.99999C4.22876 25.0001 2.34315 25.0001 1.17157 26.1717C0 27.3432 0 29.2288 0 33.0001V43.6668Z"
                                fill={color3}
                            />
                            <path
                                d="M13.332 1L13.332 9"
                                stroke={color3}
                                stroke-width="2"
                                stroke-linecap="round"
                            />
                            <path
                                d="M40 1L40 9"
                                stroke={color3}
                                stroke-width="2"
                                stroke-linecap="round"
                            />
                        </svg>
                        <div>
                            {endDate
                                ? handleDateFormat(startDate, false, false) +
                                  " - " +
                                  handleDateFormat(endDate, false, false)
                                : null}
                            {!endDate
                                ? handleDateFormat(startDate, false)
                                : null}
                        </div>
                    </div>
                    {startTime ? (
                        <div className="d-flex align-items-center mb-3">
                            <svg
                                style={{ marginRight: "20px" }}
                                width="53"
                                height="53"
                                viewBox="0 0 53 53"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <circle
                                    cx="26.5"
                                    cy="26.5"
                                    r="23"
                                    stroke={color3}
                                    stroke-width="7"
                                />
                                <path
                                    d="M37.7277 27.0905H27.3413C27.2032 27.0905 27.0913 26.9786 27.0913 26.8405V17.636"
                                    stroke={color3}
                                    stroke-width="7"
                                    stroke-linecap="round"
                                />
                            </svg>
                            <div>
                                {startTime || null}
                                {endTime ? " - " + endTime : null}
                            </div>
                        </div>
                    ) : null}
                </div>
            ) : null}
            {textData?.action_text ? (
                <div
                    style={{
                        position: "absolute",
                        color: color2,
                        backgroundColor: color3,
                        padding: "11px 27px",
                        top: "454px",
                        left: "399px",
                        fontWeight: 800,
                        fontSize: "40px",
                        display: "flex",
                        alignItems: "center",
                        zIndex: 2,
                        whiteSpace: "nowrap",
                    }}
                >
                    {textData?.action_text}
                </div>
            ) : null}
            {coverImage ? (
                <div
                    style={{
                        position: "absolute",
                        width: "732px",
                        height: "1920px",
                        top: "0px",
                        left: "0px",
                        backgroundImage: "url(" + coverImage + ")",
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "cover",
                    }}
                />
            ) : null}

            <svg
                width="88"
                height="31"
                style={{
                    position: "absolute",
                    zIndex: 1,
                    left: "15px",
                    bottom: "115px",
                }}
                viewBox="0 0 88 31"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M23.4373 0.342547L22.7588 0L22.0803 0.342547L0.839036 11.066C0.0895575 11.4443 -0.214229 12.3645 0.160511 13.1213C0.53525 13.878 1.44661 14.1847 2.19609 13.8064L22.7588 3.42547L43.3215 13.8064L44 14.1489L44.6785 13.8064L65.2412 3.42547L85.8039 13.8064C86.5534 14.1847 87.4647 13.878 87.8395 13.1213C88.2142 12.3645 87.9104 11.4443 87.161 11.066L65.9197 0.342547L65.2412 0L64.5627 0.342547L44 10.7234L23.4373 0.342547ZM23.4373 17.1936L22.7588 16.8511L22.0803 17.1936L0.839036 27.9171C0.0895575 28.2954 -0.214229 29.2156 0.160511 29.9724C0.53525 30.7291 1.44661 31.0358 2.19609 30.6575L22.7588 20.2766L43.3215 30.6575L44 31L44.6785 30.6575L65.2412 20.2766L85.8039 30.6575C86.5534 31.0358 87.4647 30.7291 87.8395 29.9724C88.2142 29.2156 87.9104 28.2954 87.161 27.9171L65.9197 17.1936L65.2412 16.8511L64.5627 17.1936L44 27.5745L23.4373 17.1936Z"
                    fill={color3}
                />
            </svg>

            <svg
                width="88"
                height="31"
                style={{
                    position: "absolute",
                    zIndex: 1,
                    right: "15px",
                    bottom: "115px",
                }}
                viewBox="0 0 88 31"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M23.4373 0.342547L22.7588 0L22.0803 0.342547L0.839036 11.066C0.0895575 11.4443 -0.214229 12.3645 0.160511 13.1213C0.53525 13.878 1.44661 14.1847 2.19609 13.8064L22.7588 3.42547L43.3215 13.8064L44 14.1489L44.6785 13.8064L65.2412 3.42547L85.8039 13.8064C86.5534 14.1847 87.4647 13.878 87.8395 13.1213C88.2142 12.3645 87.9104 11.4443 87.161 11.066L65.9197 0.342547L65.2412 0L64.5627 0.342547L44 10.7234L23.4373 0.342547ZM23.4373 17.1936L22.7588 16.8511L22.0803 17.1936L0.839036 27.9171C0.0895575 28.2954 -0.214229 29.2156 0.160511 29.9724C0.53525 30.7291 1.44661 31.0358 2.19609 30.6575L22.7588 20.2766L43.3215 30.6575L44 31L44.6785 30.6575L65.2412 20.2766L85.8039 30.6575C86.5534 31.0358 87.4647 30.7291 87.8395 29.9724C88.2142 29.2156 87.9104 28.2954 87.161 27.9171L65.9197 17.1936L65.2412 16.8511L64.5627 17.1936L44 27.5745L23.4373 17.1936Z"
                    fill={color3}
                />
            </svg>

            <svg
                width="95"
                height="144"
                style={{ position: "absolute", zIndex: 1, top: "593px" }}
                viewBox="0 0 95 144"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M-24.0769 4.96154C-24.0769 7.70172 -26.2983 9.92308 -29.0385 9.92308C-31.7786 9.92308 -34 7.70172 -34 4.96154C-34 2.22136 -31.7786 0 -29.0385 0C-26.2983 0 -24.0769 2.22136 -24.0769 4.96154Z"
                    fill={color3}
                />
                <path
                    d="M15.6154 4.96154C15.6154 7.70172 13.394 9.92308 10.6538 9.92308C7.91367 9.92308 5.69231 7.70172 5.69231 4.96154C5.69231 2.22136 7.91367 0 10.6538 0C13.394 0 15.6154 2.22136 15.6154 4.96154Z"
                    fill={color3}
                />
                <path
                    d="M55.3077 4.96154C55.3077 7.70172 53.0863 9.92308 50.3462 9.92308C47.606 9.92308 45.3846 7.70172 45.3846 4.96154C45.3846 2.22136 47.606 0 50.3462 0C53.0863 0 55.3077 2.22136 55.3077 4.96154Z"
                    fill={color3}
                />
                <path
                    d="M95 4.96154C95 7.70172 92.7786 9.92308 90.0385 9.92308C87.2983 9.92308 85.0769 7.70172 85.0769 4.96154C85.0769 2.22136 87.2983 0 90.0385 0C92.7786 0 95 2.22136 95 4.96154Z"
                    fill={color3}
                />
                <path
                    d="M-24.0769 49.6154C-24.0769 52.3556 -26.2983 54.5769 -29.0385 54.5769C-31.7786 54.5769 -34 52.3556 -34 49.6154C-34 46.8752 -31.7786 44.6538 -29.0385 44.6538C-26.2983 44.6538 -24.0769 46.8752 -24.0769 49.6154Z"
                    fill={color3}
                />
                <path
                    d="M15.6154 49.6154C15.6154 52.3555 13.394 54.5769 10.6538 54.5769C7.91367 54.5769 5.69231 52.3555 5.69231 49.6154C5.69231 46.8752 7.91367 44.6538 10.6538 44.6538C13.394 44.6538 15.6154 46.8752 15.6154 49.6154Z"
                    fill={color3}
                />
                <path
                    d="M55.3077 49.6154C55.3077 52.3555 53.0863 54.5769 50.3462 54.5769C47.606 54.5769 45.3846 52.3555 45.3846 49.6154C45.3846 46.8752 47.606 44.6538 50.3462 44.6538C53.0863 44.6538 55.3077 46.8752 55.3077 49.6154Z"
                    fill={color3}
                />
                <path
                    d="M95 49.6154C95 52.3555 92.7786 54.5769 90.0385 54.5769C87.2983 54.5769 85.0769 52.3555 85.0769 49.6154C85.0769 46.8752 87.2983 44.6538 90.0385 44.6538C92.7786 44.6538 95 46.8752 95 49.6154Z"
                    fill={color3}
                />
                <path
                    d="M-24.0769 94.2692C-24.0769 97.0094 -26.2983 99.2308 -29.0385 99.2308C-31.7786 99.2308 -34 97.0094 -34 94.2692C-34 91.5291 -31.7786 89.3077 -29.0385 89.3077C-26.2983 89.3077 -24.0769 91.5291 -24.0769 94.2692Z"
                    fill={color3}
                />
                <path
                    d="M15.6154 94.2692C15.6154 97.0094 13.394 99.2308 10.6538 99.2308C7.91367 99.2308 5.69231 97.0094 5.69231 94.2692C5.69231 91.5291 7.91367 89.3077 10.6538 89.3077C13.394 89.3077 15.6154 91.5291 15.6154 94.2692Z"
                    fill={color3}
                />
                <path
                    d="M55.3077 94.2692C55.3077 97.0094 53.0863 99.2308 50.3462 99.2308C47.606 99.2308 45.3846 97.0094 45.3846 94.2692C45.3846 91.5291 47.606 89.3077 50.3462 89.3077C53.0863 89.3077 55.3077 91.5291 55.3077 94.2692Z"
                    fill={color3}
                />
                <path
                    d="M95 94.2692C95 97.0094 92.7786 99.2308 90.0385 99.2308C87.2983 99.2308 85.0769 97.0094 85.0769 94.2692C85.0769 91.5291 87.2983 89.3077 90.0385 89.3077C92.7786 89.3077 95 91.5291 95 94.2692Z"
                    fill={color3}
                />
                <path
                    d="M-24.0769 138.923C-24.0769 141.663 -26.2983 143.885 -29.0385 143.885C-31.7786 143.885 -34 141.663 -34 138.923C-34 136.183 -31.7786 133.962 -29.0385 133.962C-26.2983 133.962 -24.0769 136.183 -24.0769 138.923Z"
                    fill={color3}
                />
                <path
                    d="M15.6154 138.923C15.6154 141.663 13.394 143.885 10.6538 143.885C7.91367 143.885 5.69231 141.663 5.69231 138.923C5.69231 136.183 7.91367 133.962 10.6538 133.962C13.394 133.962 15.6154 136.183 15.6154 138.923Z"
                    fill={color3}
                />
                <path
                    d="M55.3077 138.923C55.3077 141.663 53.0863 143.885 50.3462 143.885C47.606 143.885 45.3846 141.663 45.3846 138.923C45.3846 136.183 47.606 133.962 50.3462 133.962C53.0863 133.962 55.3077 136.183 55.3077 138.923Z"
                    fill={color3}
                />
                <path
                    d="M95 138.923C95 141.663 92.7786 143.885 90.0385 143.885C87.2983 143.885 85.0769 141.663 85.0769 138.923C85.0769 136.183 87.2983 133.962 90.0385 133.962C92.7786 133.962 95 136.183 95 138.923Z"
                    fill={color3}
                />
            </svg>
            <svg
                width="1080"
                height="1920"
                viewBox="0 0 1080 1920"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <g clip-path="url(#clip0_21_3)">
                    <rect width="1080" height="1920" fill={color1} />
                    <rect x="896" width="129" height="221" fill={color2} />
                    <path
                        d="M905.923 269.962C905.923 272.702 903.702 274.923 900.962 274.923C898.221 274.923 896 272.702 896 269.962C896 267.221 898.221 265 900.962 265C903.702 265 905.923 267.221 905.923 269.962Z"
                        fill={color3}
                    />
                    <path
                        d="M945.615 269.962C945.615 272.702 943.394 274.923 940.654 274.923C937.914 274.923 935.692 272.702 935.692 269.962C935.692 267.221 937.914 265 940.654 265C943.394 265 945.615 267.221 945.615 269.962Z"
                        fill={color3}
                    />
                    <path
                        d="M985.308 269.962C985.308 272.702 983.086 274.923 980.346 274.923C977.606 274.923 975.385 272.702 975.385 269.962C975.385 267.221 977.606 265 980.346 265C983.086 265 985.308 267.221 985.308 269.962Z"
                        fill={color3}
                    />
                    <path
                        d="M1025 269.962C1025 272.702 1022.78 274.923 1020.04 274.923C1017.3 274.923 1015.08 272.702 1015.08 269.962C1015.08 267.221 1017.3 265 1020.04 265C1022.78 265 1025 267.221 1025 269.962Z"
                        fill={color3}
                    />
                    <path
                        d="M905.923 314.615C905.923 317.356 903.702 319.577 900.962 319.577C898.221 319.577 896 317.356 896 314.615C896 311.875 898.221 309.654 900.962 309.654C903.702 309.654 905.923 311.875 905.923 314.615Z"
                        fill={color3}
                    />
                    <path
                        d="M945.615 314.615C945.615 317.356 943.394 319.577 940.654 319.577C937.914 319.577 935.692 317.356 935.692 314.615C935.692 311.875 937.914 309.654 940.654 309.654C943.394 309.654 945.615 311.875 945.615 314.615Z"
                        fill={color3}
                    />
                    <path
                        d="M985.308 314.615C985.308 317.356 983.086 319.577 980.346 319.577C977.606 319.577 975.385 317.356 975.385 314.615C975.385 311.875 977.606 309.654 980.346 309.654C983.086 309.654 985.308 311.875 985.308 314.615Z"
                        fill={color3}
                    />
                    <path
                        d="M1025 314.615C1025 317.356 1022.78 319.577 1020.04 319.577C1017.3 319.577 1015.08 317.356 1015.08 314.615C1015.08 311.875 1017.3 309.654 1020.04 309.654C1022.78 309.654 1025 311.875 1025 314.615Z"
                        fill={color3}
                    />
                    <path
                        d="M905.923 359.269C905.923 362.009 903.702 364.231 900.962 364.231C898.221 364.231 896 362.009 896 359.269C896 356.529 898.221 354.308 900.962 354.308C903.702 354.308 905.923 356.529 905.923 359.269Z"
                        fill={color3}
                    />
                    <path
                        d="M945.615 359.269C945.615 362.009 943.394 364.231 940.654 364.231C937.914 364.231 935.692 362.009 935.692 359.269C935.692 356.529 937.914 354.308 940.654 354.308C943.394 354.308 945.615 356.529 945.615 359.269Z"
                        fill={color3}
                    />
                    <path
                        d="M985.308 359.269C985.308 362.009 983.086 364.231 980.346 364.231C977.606 364.231 975.385 362.009 975.385 359.269C975.385 356.529 977.606 354.308 980.346 354.308C983.086 354.308 985.308 356.529 985.308 359.269Z"
                        fill={color3}
                    />
                    <path
                        d="M1025 359.269C1025 362.009 1022.78 364.231 1020.04 364.231C1017.3 364.231 1015.08 362.009 1015.08 359.269C1015.08 356.529 1017.3 354.308 1020.04 354.308C1022.78 354.308 1025 356.529 1025 359.269Z"
                        fill={color3}
                    />
                    <path
                        d="M905.923 403.923C905.923 406.663 903.702 408.885 900.962 408.885C898.221 408.885 896 406.663 896 403.923C896 401.183 898.221 398.962 900.962 398.962C903.702 398.962 905.923 401.183 905.923 403.923Z"
                        fill={color3}
                    />
                    <path
                        d="M945.615 403.923C945.615 406.663 943.394 408.885 940.654 408.885C937.914 408.885 935.692 406.663 935.692 403.923C935.692 401.183 937.914 398.962 940.654 398.962C943.394 398.962 945.615 401.183 945.615 403.923Z"
                        fill={color3}
                    />
                    <path
                        d="M985.308 403.923C985.308 406.663 983.086 408.885 980.346 408.885C977.606 408.885 975.385 406.663 975.385 403.923C975.385 401.183 977.606 398.962 980.346 398.962C983.086 398.962 985.308 401.183 985.308 403.923Z"
                        fill={color3}
                    />
                    <path
                        d="M1025 403.923C1025 406.663 1022.78 408.885 1020.04 408.885C1017.3 408.885 1015.08 406.663 1015.08 403.923C1015.08 401.183 1017.3 398.962 1020.04 398.962C1022.78 398.962 1025 401.183 1025 403.923Z"
                        fill={color3}
                    />
                </g>
                <defs>
                    <clipPath id="clip0_21_3">
                        <rect width="1080" height="1920" fill={color3} />
                    </clipPath>
                </defs>
            </svg>
        </div>
    );
};
