import moment from "moment";
import React from "react";
import {
    loadFont,
    marketingCreativeColorSchemes,
    marketingCreativeTemplateKeys,
} from "ui/pages/marketing/MarketingCreatives/data";
import { handleDateFormat } from "utils/Utils";

export const CalperPostDetailed = (props) => {
    const { textData, coverImage } = props;

    const defaultColorScheme =
        marketingCreativeColorSchemes?.[marketingCreativeTemplateKeys.Calper]
            ?.schemes?.[0];

    const color1 = props?.colors?.[0] || defaultColorScheme?.[0];
    const color2 = props?.colors?.[1] || defaultColorScheme?.[1];
    const color3 = props?.colors?.[2] || defaultColorScheme?.[2];

    const startDateExists = textData?.start_date_time?.exists;
    const startDate = startDateExists && textData?.start_date_time?.date;
    const startTime = startDateExists && textData?.start_date_time?.time;
    const endDateExists = textData?.end_date_time?.exists;
    const endDate = endDateExists && textData?.end_date_time?.date;
    const endTime = endDateExists && textData?.end_date_time?.time;

    React.useEffect(() => {
        loadFont(
            "https://fonts.googleapis.com/css2?family=Open+Sans&display=swap"
        );
    }, []);

    return (
        <div style={{ fontFamily: "Open Sans" }}>
            {textData?.heading ? (
                <div
                    style={{
                        position: "absolute",
                        color: color3,
                        bottom: "95px",
                        fontWeight: 800,
                        fontSize: "75px",
                        width: "1080px",
                        paddingLeft: "86px",
                        paddingRight: "75px",
                        wordBreak: "break-word",
                        textAlign: "center",
                        zIndex: 2,
                        textTransform: "uppercase",
                        textShadow: `-4px -4px ${color2}`,
                    }}
                >
                    {textData?.heading}
                </div>
            ) : null}
            <svg
                width="823"
                height="96"
                style={{
                    position: "absolute",
                    top: "932px",
                    left: "128px",
                    zIndex: 1,
                }}
                viewBox="0 0 823 96"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <rect width="823" height="96" fill={color2} />
            </svg>

            {textData?.sub_heading ? (
                <div
                    style={{
                        position: "absolute",
                        color: color3,
                        top: "981px",
                        fontWeight: 400,
                        fontSize: "36px",
                        width: "1080px",
                        paddingLeft: "128px",
                        paddingRight: "128px",
                        wordBreak: "break-word",
                        textAlign: "center",
                        zIndex: 2,
                    }}
                >
                    {textData?.sub_heading}
                </div>
            ) : null}
            {startDate ? (
                <div
                    style={{
                        position: "absolute",
                        color: color3,
                        top: "265px",
                        left: "388px",
                        fontWeight: 600,
                        fontSize: "36px",
                        backgroundColor: `${color2}80`,
                        zIndex: 1,
                        paddingLeft: "100px",
                        paddingRight: "100px",
                        whiteSpace: "nowrap",
                    }}
                >
                    {textData?.action_text ? (
                        <div
                            style={{
                                width: "100%",
                                height: "77px",
                            }}
                        />
                    ) : null}
                    <div className="d-flex align-items-center mt-3 mb-3">
                        <svg
                            width="54"
                            height="52"
                            style={{ marginRight: "20px" }}
                            viewBox="0 0 54 52"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M0 12.9999C0 10.5076 0 9.26145 0.535899 8.33325C0.886972 7.72517 1.39192 7.22022 2 6.86915C2.9282 6.33325 4.17436 6.33325 6.66667 6.33325H46.6667C49.159 6.33325 50.4051 6.33325 51.3333 6.86915C51.9414 7.22022 52.4464 7.72517 52.7974 8.33325C53.3333 9.26145 53.3333 10.5076 53.3333 12.9999C53.3333 15.4922 53.3333 16.7384 52.7974 17.6666C52.4464 18.2747 51.9414 18.7796 51.3333 19.1307C50.4051 19.6666 49.159 19.6666 46.6667 19.6666H6.66666C4.17436 19.6666 2.9282 19.6666 2 19.1307C1.39192 18.7796 0.886972 18.2747 0.535899 17.6666C0 16.7384 0 15.4922 0 12.9999Z"
                                fill={color3}
                            />
                            <path
                                d="M0 43.6668C0 47.438 0 49.3236 1.17157 50.4952C2.34315 51.6667 4.22876 51.6667 8 51.6667H45.3333C49.1046 51.6667 50.9902 51.6667 52.1618 50.4952C53.3333 49.3236 53.3333 47.438 53.3333 43.6667V33.0001C53.3333 29.2288 53.3333 27.3432 52.1618 26.1717C50.9902 25.0001 49.1046 25.0001 45.3333 25.0001H7.99999C4.22876 25.0001 2.34315 25.0001 1.17157 26.1717C0 27.3432 0 29.2288 0 33.0001V43.6668Z"
                                fill={color3}
                            />
                            <path
                                d="M13.332 1L13.332 9"
                                stroke={color3}
                                stroke-width="2"
                                stroke-linecap="round"
                            />
                            <path
                                d="M40 1L40 9"
                                stroke={color3}
                                stroke-width="2"
                                stroke-linecap="round"
                            />
                        </svg>
                        <div>
                            {endDate
                                ? handleDateFormat(startDate, false, false) +
                                  " - " +
                                  handleDateFormat(endDate, false, false)
                                : null}
                            {!endDate
                                ? handleDateFormat(startDate, false)
                                : null}
                        </div>
                    </div>
                    {startTime ? (
                        <div className="d-flex align-items-center mb-3">
                            <svg
                                style={{ marginRight: "20px" }}
                                width="53"
                                height="53"
                                viewBox="0 0 53 53"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <circle
                                    cx="26.5"
                                    cy="26.5"
                                    r="23"
                                    stroke={color3}
                                    stroke-width="7"
                                />
                                <path
                                    d="M37.7277 27.0905H27.3413C27.2032 27.0905 27.0913 26.9786 27.0913 26.8405V17.636"
                                    stroke={color3}
                                    stroke-width="7"
                                    stroke-linecap="round"
                                />
                            </svg>
                            <div>
                                {startTime || null}
                                {endTime ? " - " + endTime : null}
                            </div>
                        </div>
                    ) : null}
                </div>
            ) : null}
            {textData?.action_text ? (
                <div
                    style={{
                        position: "absolute",
                        color: color2,
                        backgroundColor: color3,
                        padding: "11px 27px",
                        top: "265px",
                        left: "388px",
                        fontWeight: 800,
                        fontSize: "40px",
                        display: "flex",
                        alignItems: "center",
                        zIndex: 2,
                        whiteSpace: "nowrap",
                    }}
                >
                    {textData?.action_text}
                </div>
            ) : null}
            {coverImage ? (
                <div
                    style={{
                        position: "absolute",
                        width: "412px",
                        height: "1080px",
                        top: "0px",
                        left: "0px",
                        backgroundImage: "url(" + coverImage + ")",
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "cover",
                    }}
                />
            ) : null}
            {textData?.details_list?.title ? (
                <div
                    style={{
                        position: "absolute",
                        color: color3,
                        top: "508px",
                        left: "434px",
                        fontWeight: 600,
                        fontSize: "48px",
                        zIndex: 1,
                        whiteSpace: "nowrap",
                    }}
                >
                    {textData?.details_list?.title}
                </div>
            ) : null}
            {textData?.details_list?.data ? (
                <ul
                    style={{
                        position: "absolute",
                        color: color3,
                        top: "598px",
                        left: "434px",
                        fontWeight: 400,
                        fontSize: "36px",
                        zIndex: 1,
                        whiteSpace: "nowrap",
                    }}
                >
                    {textData?.details_list?.data?.map((item) => (
                        <li>{item}</li>
                    ))}
                </ul>
            ) : null}

            <svg
                width="88"
                height="31"
                style={{
                    position: "absolute",
                    zIndex: 1,
                    left: "15px",
                    bottom: "115px",
                }}
                viewBox="0 0 88 31"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M23.4373 0.342547L22.7588 0L22.0803 0.342547L0.839036 11.066C0.0895575 11.4443 -0.214229 12.3645 0.160511 13.1213C0.53525 13.878 1.44661 14.1847 2.19609 13.8064L22.7588 3.42547L43.3215 13.8064L44 14.1489L44.6785 13.8064L65.2412 3.42547L85.8039 13.8064C86.5534 14.1847 87.4647 13.878 87.8395 13.1213C88.2142 12.3645 87.9104 11.4443 87.161 11.066L65.9197 0.342547L65.2412 0L64.5627 0.342547L44 10.7234L23.4373 0.342547ZM23.4373 17.1936L22.7588 16.8511L22.0803 17.1936L0.839036 27.9171C0.0895575 28.2954 -0.214229 29.2156 0.160511 29.9724C0.53525 30.7291 1.44661 31.0358 2.19609 30.6575L22.7588 20.2766L43.3215 30.6575L44 31L44.6785 30.6575L65.2412 20.2766L85.8039 30.6575C86.5534 31.0358 87.4647 30.7291 87.8395 29.9724C88.2142 29.2156 87.9104 28.2954 87.161 27.9171L65.9197 17.1936L65.2412 16.8511L64.5627 17.1936L44 27.5745L23.4373 17.1936Z"
                    fill={color3}
                />
            </svg>

            <svg
                width="88"
                height="31"
                style={{
                    position: "absolute",
                    zIndex: 1,
                    right: "15px",
                    bottom: "115px",
                }}
                viewBox="0 0 88 31"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M23.4373 0.342547L22.7588 0L22.0803 0.342547L0.839036 11.066C0.0895575 11.4443 -0.214229 12.3645 0.160511 13.1213C0.53525 13.878 1.44661 14.1847 2.19609 13.8064L22.7588 3.42547L43.3215 13.8064L44 14.1489L44.6785 13.8064L65.2412 3.42547L85.8039 13.8064C86.5534 14.1847 87.4647 13.878 87.8395 13.1213C88.2142 12.3645 87.9104 11.4443 87.161 11.066L65.9197 0.342547L65.2412 0L64.5627 0.342547L44 10.7234L23.4373 0.342547ZM23.4373 17.1936L22.7588 16.8511L22.0803 17.1936L0.839036 27.9171C0.0895575 28.2954 -0.214229 29.2156 0.160511 29.9724C0.53525 30.7291 1.44661 31.0358 2.19609 30.6575L22.7588 20.2766L43.3215 30.6575L44 31L44.6785 30.6575L65.2412 20.2766L85.8039 30.6575C86.5534 31.0358 87.4647 30.7291 87.8395 29.9724C88.2142 29.2156 87.9104 28.2954 87.161 27.9171L65.9197 17.1936L65.2412 16.8511L64.5627 17.1936L44 27.5745L23.4373 17.1936Z"
                    fill={color3}
                />
            </svg>

            <svg
                width="1080"
                height="1080"
                viewBox="0 0 1080 1080"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <rect width="1080" height="1080" fill={color1} />
                <rect x="885" width="129" height="221" fill={color2} />
                <path
                    d="M894.923 269.962C894.923 272.702 892.702 274.923 889.962 274.923C887.221 274.923 885 272.702 885 269.962C885 267.221 887.221 265 889.962 265C892.702 265 894.923 267.221 894.923 269.962Z"
                    fill={color3}
                />
                <path
                    d="M934.615 269.962C934.615 272.702 932.394 274.923 929.654 274.923C926.914 274.923 924.692 272.702 924.692 269.962C924.692 267.221 926.914 265 929.654 265C932.394 265 934.615 267.221 934.615 269.962Z"
                    fill={color3}
                />
                <path
                    d="M974.308 269.962C974.308 272.702 972.086 274.923 969.346 274.923C966.606 274.923 964.385 272.702 964.385 269.962C964.385 267.221 966.606 265 969.346 265C972.086 265 974.308 267.221 974.308 269.962Z"
                    fill={color3}
                />
                <path
                    d="M1014 269.962C1014 272.702 1011.78 274.923 1009.04 274.923C1006.3 274.923 1004.08 272.702 1004.08 269.962C1004.08 267.221 1006.3 265 1009.04 265C1011.78 265 1014 267.221 1014 269.962Z"
                    fill={color3}
                />
                <path
                    d="M894.923 314.615C894.923 317.356 892.702 319.577 889.962 319.577C887.221 319.577 885 317.356 885 314.615C885 311.875 887.221 309.654 889.962 309.654C892.702 309.654 894.923 311.875 894.923 314.615Z"
                    fill={color3}
                />
                <path
                    d="M934.615 314.615C934.615 317.356 932.394 319.577 929.654 319.577C926.914 319.577 924.692 317.356 924.692 314.615C924.692 311.875 926.914 309.654 929.654 309.654C932.394 309.654 934.615 311.875 934.615 314.615Z"
                    fill={color3}
                />
                <path
                    d="M974.308 314.615C974.308 317.356 972.086 319.577 969.346 319.577C966.606 319.577 964.385 317.356 964.385 314.615C964.385 311.875 966.606 309.654 969.346 309.654C972.086 309.654 974.308 311.875 974.308 314.615Z"
                    fill={color3}
                />
                <path
                    d="M1014 314.615C1014 317.356 1011.78 319.577 1009.04 319.577C1006.3 319.577 1004.08 317.356 1004.08 314.615C1004.08 311.875 1006.3 309.654 1009.04 309.654C1011.78 309.654 1014 311.875 1014 314.615Z"
                    fill={color3}
                />
                <path
                    d="M894.923 359.269C894.923 362.009 892.702 364.231 889.962 364.231C887.221 364.231 885 362.009 885 359.269C885 356.529 887.221 354.308 889.962 354.308C892.702 354.308 894.923 356.529 894.923 359.269Z"
                    fill={color3}
                />
                <path
                    d="M934.615 359.269C934.615 362.009 932.394 364.231 929.654 364.231C926.914 364.231 924.692 362.009 924.692 359.269C924.692 356.529 926.914 354.308 929.654 354.308C932.394 354.308 934.615 356.529 934.615 359.269Z"
                    fill={color3}
                />
                <path
                    d="M974.308 359.269C974.308 362.009 972.086 364.231 969.346 364.231C966.606 364.231 964.385 362.009 964.385 359.269C964.385 356.529 966.606 354.308 969.346 354.308C972.086 354.308 974.308 356.529 974.308 359.269Z"
                    fill={color3}
                />
                <path
                    d="M1014 359.269C1014 362.009 1011.78 364.231 1009.04 364.231C1006.3 364.231 1004.08 362.009 1004.08 359.269C1004.08 356.529 1006.3 354.308 1009.04 354.308C1011.78 354.308 1014 356.529 1014 359.269Z"
                    fill={color3}
                />
                <path
                    d="M894.923 403.923C894.923 406.663 892.702 408.885 889.962 408.885C887.221 408.885 885 406.663 885 403.923C885 401.183 887.221 398.962 889.962 398.962C892.702 398.962 894.923 401.183 894.923 403.923Z"
                    fill={color3}
                />
                <path
                    d="M934.615 403.923C934.615 406.663 932.394 408.885 929.654 408.885C926.914 408.885 924.692 406.663 924.692 403.923C924.692 401.183 926.914 398.962 929.654 398.962C932.394 398.962 934.615 401.183 934.615 403.923Z"
                    fill={color3}
                />
                <path
                    d="M974.308 403.923C974.308 406.663 972.086 408.885 969.346 408.885C966.606 408.885 964.385 406.663 964.385 403.923C964.385 401.183 966.606 398.962 969.346 398.962C972.086 398.962 974.308 401.183 974.308 403.923Z"
                    fill={color3}
                />
                <path
                    d="M1014 403.923C1014 406.663 1011.78 408.885 1009.04 408.885C1006.3 408.885 1004.08 406.663 1004.08 403.923C1004.08 401.183 1006.3 398.962 1009.04 398.962C1011.78 398.962 1014 401.183 1014 403.923Z"
                    fill={color3}
                />
            </svg>
        </div>
    );
};
